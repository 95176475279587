import React from "react"
import { PhoneOutlined } from "@ant-design/icons"
import { Button, Typography } from "antd"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import WRPSearch from "../WRPSearch/WRPSearch"
import ButtonModal from "../ButtonModal/ButtonModal"
import OptInModal from "../Forms/OptInModal"
import style from "./BookingHero.module.scss"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"

class BookingHero extends React.Component {
  static contextType = MediaContext

  static propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hotelID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modalImg: PropTypes.object.isRequired,
    phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }

  static defaultProps = {
    alt: null,
    fluid: null,
    height: `70vh`,
    hotelID: process.env.GATSBY_WRP_HOTEL_ID,
    modalImg: null,
    phone: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      vis: false,
    }
  }

  toggleModal = () => {
    this.setState({
      vis: !this.state.vis,
    })
  }

  render() {
    const { height, modalImg, phone, mobileHero, desktopHero } = this.props
    const { width } = this.context

    const hero = width < 560 ? mobileHero : desktopHero
    const alt = hero.description

    return (
      <div className={style.wrapper} style={{ height: height }}>
        <Img alt={alt} className={style.image} fluid={hero.fluid} />
        <div className={style.searchWrapper}>
          <Typography.Title className={style.title} level={1}>
            Book Your Suite Today
          </Typography.Title>
          <WRPSearch hotelID={this.props.hotelID} phone={phone} />
          <div className={style.buttonWrapper}>
            <div className={style.email}>
              <ButtonModal
                cta={
                  this.context.width < 380 ? `Email Offers` : `Get Email Offers`
                }
                image={modalImg}
                title="Exclusive Email Savings"
                body={
                  <>
                    <Typography.Paragraph strong>
                      Looking for exclusive savings and offers for The
                      Residences at Biltmore All-Suite Hotel?
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      Sign up below and have the latest updates, events, and
                      special offers delivered right to you inbox! Unsubscribe
                      any time by clicking the “Unsubscribe” button found in
                      every email we send you.
                    </Typography.Paragraph>
                    <OptInModal
                      automationId={39}
                      formName="Email Offers"
                      gtmClickId="get-email-offers-form"
                      cancelText="Nevermind"
                      submitText="Sign Up!"
                      toggleModal={this.toggleModal}
                    />
                  </>
                }
                hideButtons={true}
                visible={this.state.vis}
                toggleModal={this.toggleModal}
              />
            </div>
            <div className={style.call}>
              <Button className={style.call} size="large" type="primary">
                <a href={`tel:${phone}`}>
                  {this.context.width < 380 ? (
                    `Call Now`
                  ) : (
                    <span>
                      <PhoneOutlined className={style.phoneIcon} />
                      1-888-786-3648
                    </span>
                  )}
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        # contact: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
        #   phone
        # }
        hero: contentfulAsset(title: { eq: "RAB - Home Hero 1" }) {
          ...HeroFluid
        }
        modalImg: contentfulAsset(title: { eq: "Biltmore - Front - Autumn" }) {
          description
          fluid(maxWidth: 520) {
            ...fluid_withWebp_tracedSVG
          }
        }
      }
    `}
    render={data => (
      <BookingHero
        mobileHero={data.hero}
        desktopHero={data.hero}
        height={props.height}
        modalImg={data.modalImg}
        phone="1-888-786-3648"
      />
    )}
  />
)
