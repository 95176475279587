import React from "react"
import { Button, Modal, Typography } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import style from "./ButtonModal.module.scss"

class ButtonModal extends React.Component {
  static propTypes = {
    cta: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    hideButtons: PropTypes.bool,
    cancelButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    okButton: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }

  static defaultProps = {
    cta: null,
    image: null,
    title: null,
    body: null,
    hideButtons: false,
    cancelButton: "Cancel",
    okButton: "Okay",
  }

  state = {
    vis: false,
  }

  toggleModal = () => {
    this.setState({
      vis: !this.state.vis,
    })
  }

  render() {
    const {
      cta,
      image,
      title,
      body,
      hideButtons,
      cancelButton,
      okButton,
    } = this.props

    return (
      <>
        <Button
          className={style.button}
          onClick={this.props.toggleModal || this.toggleModal}
          size="large"
          type="primary"
        >
          {cta}
        </Button>
        <Modal
          bodyStyle={{ padding: 0 }}
          centered
          className={style.modal}
          closeIcon={<CloseOutlined className={style.closeIcon} />}
          footer={null}
          onCancel={this.props.toggleModal || this.toggleModal}
          visible={
            this.props.visible === "undefined"
              ? this.state.visible
              : this.props.visible
          }
        >
          <Img
            alt={image.description || ""}
            className={style.image}
            fluid={image.fluid}
          />
          <div className={style.content}>
            <Typography.Title className={style.modalTitle} level={4}>
              {title}
            </Typography.Title>
            <Typography.Paragraph className={style.body}>
              {body}
            </Typography.Paragraph>
            {hideButtons ? (
              <></>
            ) : (
              <div className={style.buttonWrapper}>
                <Button
                  className={style.cancel}
                  onClick={this.toggleModal}
                  size="large"
                  type="ghost"
                >
                  {cancelButton}
                </Button>
                <Button className={style.ok} size="large">
                  {okButton}
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </>
    )
  }
}

export default ButtonModal
